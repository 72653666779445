import { Flex, IconButton, Text, useColorMode } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/switch";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ThemeSwitcher = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text>Dark Mode</Text>
      <Switch
        size="lg"
        checked={colorMode == "dark"}
        onChange={toggleColorMode}
      />
    </Flex>
  );
};

export const ThemeIconButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <IconButton
        bg="transparent"
        aria-label="select theme"
        icon={<FontAwesomeIcon icon={colorMode == "light" ? faSun : faMoon} />}
        onClick={toggleColorMode}
      />
    </Flex>
  );
};
