import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { User } from "../../api-client";
import { ThemeIconButton } from "../themes/theme-switcher";

export type NavProps = {
  user?: User;
};

export const NavLayout = ({ user }: NavProps) => {
  return (
    <Flex
      width="100%"
      mb="md"
      alignItems="center"
      justifyContent="space-between"
      my="4"
    >
      <Link to="/">
        <Heading size="md">Veld.Space</Heading>
      </Link>
      <ButtonGroup>
        <ThemeIconButton />
        {user ? (
          <Avatar src={user.avatar ?? ""} />
        ) : (
          <ButtonGroup>
            <Link to="/login">
              <Button bgColor="blue.400">Login</Button>
            </Link>
            <Link to="/register">
              <Button>Register</Button>
            </Link>
          </ButtonGroup>
        )}
      </ButtonGroup>
    </Flex>
  );
};
