import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import "../style/variables.css";
import { Box, Container } from "@chakra-ui/react";
import { Nav } from "./nav";

const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/cep5qnb.css" />
      </Helmet>
      <Box width="100%" minHeight="100vh">
        <Container maxW="container.lg" centerContent>
          <Nav />
          {children}
        </Container>
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
