import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { MobileNavLayout } from "./navigation/mobile-nav-layout";
import { NavLayout } from "./navigation/nav-layout";

export const Nav = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  return isDesktop ? <NavLayout /> : <MobileNavLayout />;
};
