import axios, { AxiosInstance } from "axios";

export interface User {
  displayName: string;
  name: string;
  avatar?: string;
}

const getToken = () => {
  return localStorage?.getItem("token");
}

export class ApiClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: localStorage.getItem("api") || "https://localhost:5001/",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  async getCurrentUser(): Promise<User | null> {
    const token = getToken();
    if(!token) {
      return null;
    }
    const response = await this.client.get("account/@me", {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    });
    return response.data;
  }

  async register(user: any): Promise<string> {
    let response = await this.client.post("account/signup", user);
    return response.data.token;
  }

  async login(username: string, password: string): Promise<string> {
    let response = await this.client.post("account/signin", {
      username, password
    });
    return response.data.token;
  }
}

export const apiClient = typeof window !== "undefined" ? new ApiClient() : {};