import { useState, useEffect } from "react";
import { apiClient, User } from "../api-client";

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    apiClient.getCurrentUser().then(setUser);
  }, []);

  return user || undefined;
};
