import { Drawer, DrawerFooter } from "@chakra-ui/modal";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import { useUser } from "../../hooks/useUser";
import { ThemeSwitcher } from "../themes/theme-switcher";

export const MobileNavLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const color = useColorModeValue("white", "gray.800");
  const user = useUser();

  return (
    <>
      <Box h="64px" />
      <Box width="full" bg={color} py="2" px="4" position="fixed" zIndex="10">
        <Flex alignItems="center" justifyContent="space-between">
          <Link to="/">
            <Heading size="md">Veld.Space</Heading>
          </Link>
          <Box>
            <IconButton
              aria-label="bars"
              icon={<FontAwesomeIcon icon={faBars} />}
              onClick={onOpen}
            />
          </Box>
        </Flex>
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Veld.Space</DrawerHeader>
            <DrawerBody>
              {user && (
                <Flex alignItems="center">
                  <Avatar mr="4" src={user.avatar}></Avatar>
                  <Text>{user.displayName || `@${user.name}`}</Text>
                </Flex>
              )}
              <Divider my="4" colorScheme="gray" />
              <Text as="p" size="2">
                Home
              </Text>
              <Divider my="4" />
              <Text as="p" size="2">
                Trending Now
              </Text>
            </DrawerBody>
            <DrawerFooter>
              <Box width="100%">
                <ThemeSwitcher />
              </Box>
            </DrawerFooter>
            <Divider />
            <DrawerFooter>
              {user ? (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                >
                  <Button>Sign out</Button>
                </Flex>
              ) : (
                <ButtonGroup width="100%">
                  <Button isFullWidth bgColor="blue.400">
                    Log In
                  </Button>
                  <Button isFullWidth>Register</Button>
                </ButtonGroup>
              )}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
